<template>
  <div class="page_wrap">
    <top></top>
    <div class="home fs30" id="target_top">
      <div class="header flex bg-ff align-center justify-center"></div>
      <!-- 顶部end -->
      <!-- 主体内容 start -->
      <div class="content">
        <div class="flex">
          <div class="imgbox">
            <div>
              <img class="bigimg" :src="mianimg" alt="" />
            </div>
            <div>
              <img
                class="smimg"
                :class="active == index ? 'activeimg' : ''"
                v-for="(item, index) in goodsinfo.images"
                :key="index"
                @click="changeimg(index)"
                :src="item"
                alt=""
              />
            </div>
          </div>
          <div class="spxx">
            <div>
              <img src="../../assets/image/fybq.png" alt="" />
              <span class="spname">{{ goodsinfo.name }}</span>
            </div>
            <div class="pricebox">
              <span class="price fs-12" v-if="userInfo.user_level > 0"
                >会员价</span
              >
              <span class="price" style="font-size: 20px"
                >￥{{
                  userInfo.user_level == 0
                    ? goodsinfo.shop_price
                    : goodsinfo.member_price
                }}</span
              >
              <span class="price fs-12">/页</span>
              <span class="mprice fs-12" v-if="userInfo.user_level > 0">{{
                goodsinfo.market_price
              }}</span>
              <span class="merice fs-12" v-if="userInfo.user_level == 0"
                >会员价￥{{ goodsinfo.member_price }}</span
              >
            </div>
            <div class="skubox">
              <div
                v-for="(item, index) in goodsinfo.sku_list"
                :key="index"
                class="skuitem"
                @click="skuactive = index"
                :class="skuactive == index ? 'skuactive' : ''"
              >
                {{ item.key_name }}
              </div>
            </div>
            <div class="spnum">
              <span class="numlabel">请选择拼页数量</span>
              <el-input-number
                v-model="num"
                @change="handleChange"
                :min="1"
              ></el-input-number>
            </div>
            <el-button
              v-if="grouplist.length"
              class="ptbtn"
              @click="tospellpage(grouplist[0])"
              >参与拼页</el-button
            >
            <el-button v-else class="ptbtn" @click="partgroup"
              >发起拼页</el-button
            >
          </div>
        </div>
        <div class="ptbox" v-if="grouplist.length">
          <div class="flex justify-between">
            <div class="titles" style="font-size: 16px">
              {{ grouplist.length }}人正在拼页,可直接参与
            </div>
            <div>
              <div>
                <span
                  class="righttit"
                  style="font-size: 16px"
                  @click="dialogVisible = true"
                  >查看全部</span
                >
                <img
                  class="rightimg"
                  src="../../assets/image/ckqb.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div
            class="ptlist flex justify-between"
            v-for="(item, index) in grouplist"
            :key="index"
            :class="index != grouplist.length - 1 ? 'margin-b-32' : ''"
          >
            <div>
              <img class="block-36 headimg" :src="item.user_head" alt="" />
              <span style="font-size: 16px; margin-left: 16px; color: #333">{{
                item.user_name
              }}</span>
              <span style="font-size: 16px; margin-left: 106px; color: #333"
                >还差</span
              >
              <span style="font-size: 16px; color: #e54f42"
                >{{ item.join_number - item.num }}页</span
              >
              <span style="font-size: 16px; color: #333">拼成</span>
            </div>
            <div>
              <el-button class="pybtn" @click="tospellpage(item)"
                >去拼页</el-button
              >
            </div>
          </div>
        </div>
        <div class="psjj">拼书简介</div>
        <div class="bootom-line"></div>
        <div class="bt-tit">图文详情</div>
        <div class="twxq" v-html="goodsinfo.body"></div>
      </div>
      <!-- 主体内容 end -->
      <!-- 悬浮按钮 -->
      <div
        class="float-box flex flex-direction align-center justify-around"
        v-show="scrollTop > 500"
      >
        <img
          src="../../assets/image/home/top.png"
          class="block-28"
          @click="scrollToTop"
        />
        <!-- <img src="../../../assets/image/home/add.png" class="block-19"> -->
        <i
          class="el-icon-circle-plus-outline fs28"
          :class="dialogTableVisible ? 'text-e54f' : 'text-333'"
          @click="dialogTableVisible = true"
        ></i>
        <!-- <img src="../../assets/image/home/uptade_black.png" class="block-28 point" @click="update" :class="showRotate2 ? 'loading' : '' "> -->
      </div>
      <div
        class="mask"
        @click="dialogTableVisible = false"
        v-if="dialogTableVisible"
      ></div>
      <div
        class="box-content bg-white radius-3 radius-10"
        v-if="dialogTableVisible"
      >
        <div class="" style="position: relative">
          <router-link to="/publish">
            <div
              class="flex align-center padding-tb-20 padding-lr-30 solid-bottom point"
            >
              <img src="../../assets/image/home/add1.png" class="block-24" />
              <span class="padding-left-10 fs14">动态</span>
            </div>
          </router-link>
          <div
            class="flex align-center padding-tb-20 padding-lr-30 solid-bottom point"
            @click="toPublish(1, '/editArticle')"
          >
            <img src="../../assets/image/home/add2.png" class="block-24" />
            <span class="padding-left-10 fs14">文章（作家权限）</span>
          </div>
          <div
            class="flex align-center padding-tb-20 padding-lr-30 point"
            @click="toPublish(2, '/editBook')"
          >
            <img src="../../assets/image/home/add3.png" class="block-24" />
            <span class="padding-left-10 fs14">电子书（作家权限）</span>
          </div>
          <div class="bg-f6f7 height10 w270"></div>
        </div>
        <div
          class="padding-tb-15 text-center fs14 text-e54f"
          @click="dialogTableVisible = false"
        >
          取消
        </div>
      </div>
    </div>
    <bottom></bottom>
    <!-- 拼团弹窗 -->
    <el-dialog
      title="全部拼页"
      :visible.sync="dialogVisible"
      width="35%"
      center
    >
      <div
        class="ptlist flex justify-between"
        v-for="(item, index) in grouplist"
        :key="index"
        :class="index != grouplist.length - 1 ? 'margin-b-32' : ''"
      >
        <div>
          <img class="block-36 headimg" :src="item.user_head" alt="" />
          <span style="font-size: 16px; margin-left: 16px; color: #333">{{
            item.user_name
          }}</span>
          <span style="font-size: 16px; margin-left: 16px; color: #333"
            >还差</span
          >
          <span style="font-size: 16px; color: #e54f42">{{ item.num }}页</span>
          <span style="font-size: 16px; color: #333">拼成</span>
        </div>
        <div>
          <el-button class="pybtn" @click="tospellpage(item)">去拼页</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :title="'参与' + ptdata.user_name + '的拼书'"
      :visible.sync="qpyVisible"
      width="35%"
      center
    >
      <div>
        <div style="text-align: center">
          <span style="font-size: 14px; color: #999"
            >剩余{{ ptdata.num }}页即可拼页成功</span
          >
        </div>
        <div
          class="groupuser"
          v-for="(item, index) in ptdata.user_list"
          :key="index"
        >
          <div>
            <img class="headimg" :src="item.user_head" alt="" />
            <div style="font-size: 16px; color: #999">{{ item.user_name }}</div>
          </div>
        </div>
        <div class="partbox">
          <el-button class="partbtn" @click="partgroup">参与拼团</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dynamic from "@/components/dynamic/dynamic";
import bookList from "@/components/book-list/book-list";
import top from "@/components/top";
import bottom from "@/components/bottom";
import loadMore from "@/components/load-more/load-more.vue";
export default {
  components: {
    top,
    bottom,
    dynamic,
    bookList,
    loadMore,
  },
  name: "Home",
  data() {
    return {
      mianimg: "",
      isInit: false,
      page: 1,
      goods_id: "",
      totalSize: 0, //总页数
      loading: false,
      dialogVisible: false,
      qpyVisible: false,
      active: 0,
      num: 1,
      skuactive: 0,
      goodsinfo: {},
      ptdata: {},
      grouplist: [],
      group_id: "",
      iscompleted: false, //当设置为 true 时将不会再触发 infinite-scroll 事件.当所有数据全部加载完毕后，建议设置为 true
      scrollTop: 0,
      dialogTableVisible: false, //新建弹框
      lists: [],
    };
  },
  computed: {
    // 是否登录状态
    isLogin() {
      return this.$store.getters.isLogin;
    },
    // 是否是作家
    is_writer() {
      return this.$store.getters.is_writer;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  mounted() {
    this.goods_id = this.$route.query.id;
    window.addEventListener("scroll", this.handleScroll);
    this.loadData();
    window["getSonData"] = () => {
      this.loadData();
    };
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    /* 通过code获取openid */
    codeGetOpenid(code) {
      this.ajax(
        "post",
        "/v1/619714950d880",
        {
          code: code,
        },
        (res) => {
          if (res.code == 1) {
            localStorage.setItem(
              "USER_INFO",
              JSON.stringify(res.data.userinfo)
            );
            localStorage.setItem("user_token", res.data.userinfo.user_token);
            this.$store.commit("updateUserInfo", res.data.userinfo);
            // window.location.reload();  //刷新页面
          } else if (res.code == -999 && res.data.result) {
            this.$router.push({
              path: "/bind_phone",
              query: {
                openid: res.data.openid,
                unionid: res.data.result.unionid,
                nickName: res.data.result.nickname,
                avatarUrl: res.data.result.headimgurl,
              },
            });
          }
        },
        (err) => {
          console.log("微信登录code获取失败", err);
        }
      );
    },
    handleChange(value) {
      console.log(value);
    },
    // 去发布
    async toPublish(type, path) {
      let auditInfo = await this.getAuditInfo();
      // 0  审核中
      // 1  审核通过
      // 2  审核拒绝
      // 3  待支付
      let info = type == 1 ? "文章" : "电子书";
      switch (auditInfo.status) {
        case -99:
          this.$confirm(`只有作家才可以发布${info}哦，去认证?`, "提示", {
            confirmButtonText: "好的",
            type: "info",
          })
            .then(() => {
              this.$router.push("/authen");
            })
            .catch(() => {});
          break;
        case 0:
          this.$confirm("认证审核中，请耐心等待", "提示", {
            confirmButtonText: "好的",
            type: "info",
          })
            .then(() => {})
            .catch(() => {});
          break;
        case 1:
          this.$router.push({
            path: path,
            query: {
              ducoment_type: 2,
            },
          });
          break;
        case 2:
          let reasons = auditInfo.reason || "暂无原因";
          this.$confirm(
            "认证审核未通过，拒绝原因为：" + reasons + "，请重新提交",
            "提示",
            {
              confirmButtonText: "好的",
              cancelButtonText: "不了",
              type: "info",
            }
          )
            .then(() => {
              this.$router.push("/authen");
            })
            .catch(() => {});
          break;
        case 3:
          this.$confirm("认证未支付，去支付？", "提示", {
            confirmButtonText: "好的",
            cancelButtonText: "不了",
            type: "info",
          })
            .then(() => {
              this.addOrder(auditInfo.period_id, auditInfo.id);
            })
            .catch(() => {});
          break;
        default:
          break;
      }
    },
    // 获取认证信息
    getAuditInfo() {
      return new Promise((resolve, reject) => {
        this.ajax("post", "/v1/6142e36210b86", {}, (res) => {
          if (res.code == -201) {
            this.$router.push("/login");
          } else {
            resolve(res.data);
          }
        });
      });
    },
    // 统一下单接口
    addOrder(period_id, certified_id) {
      this.ajax(
        "post",
        "/v1/5d784b976769e",
        {
          order_type: 4, //1 ：'现金充值'，2：'虚拟币充值'，3 ：'商城交易'，4：认证支付5：打赏6：文章购买
          period_id: period_id,
          certified_id: certified_id,
        },
        (res) => {
          if (res.code == 1) {
            let order_sn = res.data.order_sn;
            this.$router.push({
              path: "/payMoney",
              query: {
                order_sn: res.data.order_sn,
                order_money: res.data.money,
                return_url: JSON.stringify(window.location.href),
              },
            });
          } else {
            this.$message.error(res.msg);
          }
        }
      );
    },
    //参与拼团
    partgroup() {
      this.$router.push({
        path: "/confirm-order",
        query: {
          goods_id: this.goods_id,
          sku_id: this.goodsinfo.sku_list[this.skuactive].sku_id,
          number: this.num,
          group_id: this.group_id,
        },
      });
    },
    // 页数改变
    handleSizeChange(e) {
      console.log(e);
      this.page = e;
      this.loadData();
      target_top.scrollIntoView();
    },
    // 跳页
    handleCurrentChange(e) {
      console.log(e);
      this.page = e;
      this.loadData();
      target_top.scrollIntoView();
    },
    handleScroll(e) {
      this.scrollTop =
        e.target.documentElement.scrollTop || e.target.body.scrollTop; // 执行代码
    },
    // 发布动态
    publish() {
      this.$router.push("/publishDynac");
    },
    getNews() {
      this.ajax(
        "post",
        "/common/newsList",
        {
          cate_id: this.calatId,
        },
        (res) => {
          if (res.code == 1) {
            this.newsList = res.data;
          } else {
            this.$message.error(res.msg);
          }
        }
      );
    },
    // 显示按钮的显示与隐藏
    scrollToTop() {
      let scrollToptimer = setInterval(function () {
        // console.log("定时循环回到顶部");
        var top = document.body.scrollTop || document.documentElement.scrollTop;
        var speed = top / 4;
        document.documentElement.scrollTop -= speed;
        if (top == 0) {
          clearInterval(scrollToptimer);
        }
      }, 30);
    },
    // 推荐数据加载
    loadData() {
      this.ajax(
        "post",
        "/v1/6380a45288871",
        {
          goods_id: this.goods_id,
        },
        (res) => {
          this.isInit = true;
          if (res.code == 1) {
            this.goodsinfo = res.data;
            (this.grouplist =
              res.data.group_list && res.data.group_list.activity_group
                ? res.data.group_list.activity_group.join_list
                : []),
              (this.mianimg = res.data.images ? res.data.images[0] : "");
            console.log(res.data);
          } else {
            this.$message.error(res.msg);
          }
        },
        (err) => {
          console.log(err);
          this.hadGotCode = false;
        }
      );
    },
    changeimg(index) {
      this.active = index;
      this.mianimg = this.goodsinfo.images[index];
    },
    tospellpage(item) {
      this.ptdata = item;
      this.group_id = item.id;
      this.$router.push({
        path: "/confirm-order",
        query: {
          goods_id: this.goods_id,
          sku_id: this.goodsinfo.sku_list[this.skuactive].sku_id,
          number: this.num,
          group_id: this.group_id,
        },
      });
    },
  },
};
</script>
<style >
.jsq .el-input__inner {
  width: 40px;
  height: 36px;
  line-height: 36px;
}
</style>
<style lang="scss" scoped="scoped">
// @import url("./home.css");
.loading {
  -webkit-animation: rotation 0.4s linear;
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(180deg);
  }
}
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
/* 右侧信息 */
.box-content {
  position: fixed;
  width: 270px;
  right: 315px;
  bottom: 93px;
  z-index: 1510;
  background-color: #ffffff;
}
.float-box {
  position: fixed;
  right: 245px;
  bottom: 138px;
  width: 72px;
  height: 246px;
  background: #ffffff;
  box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.08);
  opacity: 1;
  border-radius: 43px;
}
.content {
  margin-top: 113px;
  margin-left: 19%;
  width: 62%;
  .imgbox {
    width: 40%;
    .bigimg {
      width: 100%;
    }
    .smimg {
      width: 18%;
      margin-right: 2%;
    }
    .activeimg {
      border: 1px solid #e54f42;
    }
  }
  .spxx {
    flex: 1;
    padding-left: 32px;
    .spname {
      color: #333;
      font-size: 24px;
      position: relative;
      left: 10px;
      top: -3px;
    }
  }
  .pricebox {
    background: rgba(229, 79, 66, 0.1);
    height: 64px;
    line-height: 64px;
    padding-left: 24px;
    margin-top: 20px;
    .price {
      color: #e54f42;
    }
    .mprice {
      color: #999;
      text-decoration: line-through;
      margin-left: 16px;
    }
    .merice {
      color: #e54f42;
      margin-left: 16px;
    }
  }
  .skubox {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    .skuitem {
      background: #f6f7f9;
      padding: 9px 14px;
      margin-right: 10px;
      color: #333;
      font-size: 14px;
      border-radius: 8px;
      cursor: pointer;
    }
    .skuactive {
      background: #e54f42;
      color: #fff;
    }
  }
  .spnum {
    margin-top: 24px;
    .numlabel {
      font-size: 14px;
      color: #333;
      margin-right: 24px;
    }
    .el-input-number.is-without-controls .el-input__inner {
      width: 40px;
      height: 36px;
      line-height: 36px;
    }
  }
  .ptlist {
    padding: 40px 0;
    .margin-b-32 {
      margin-bottom: 32px;
    }
    .headimg {
      border-radius: 50%;
      position: relative;
      top: 7px;
    }
  }
  .ptbtn {
    background: #e54f42;
    border-radius: 64px;
    width: 269px;
    color: #fff;
    margin-top: 50px;
  }
  .ptbox {
    padding: 32px;
    margin-top: 24px;
    height: 400px;
    overflow: hidden;
    border: 1px solid #eee;
    .titles {
      color: #333;
    }
    .pybtn {
      width: 90px;
      background: #e54f42;
      border-radius: 44px;
      color: #fff;
    }
    .righttit {
      color: #4177dd;
      margin-right: 6px;
      cursor: pointer;
    }
    .rightimg {
      width: 12px;
      height: 20px;
    }
  }
  .psjj {
    width: 200px;
    height: 50px;
    background: #e54f42;
    color: #fff;
    font-size: 16px;
    line-height: 50px;
    text-align: center;
    margin-top: 32px;
  }
  .bootom-line {
    width: 100%;
    height: 2px;
    background: #e54f42;
  }
  .bt-tit {
    width: 100%;
    color: #333;
    font-size: 32px;
    padding: 15px 0 23px 0;
    text-align: center;
  }
  .twxq {
    width: 100%;
  }
}
.ptlist {
  padding: 40px 0;
  .margin-b-32 {
    margin-bottom: 32px;
  }
  .headimg {
    border-radius: 50%;
    position: relative;
    top: 7px;
  }
  .pybtn {
    width: 90px;
    background: #e54f42;
    border-radius: 44px;
    color: #fff;
  }
}
.groupuser {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 71px;
  .headimg {
    width: 104px;
    height: 104px;
    border-radius: 50%;
    margin-bottom: 8px;
  }
}
.partbox {
  margin: 94px 0 12px 0;
  text-align: center;
  .partbtn {
    background: #e54f42;
    border-radius: 64px;
    color: #fff;
  }
}
.bg-ff {
  background: #fff;
}
.fs-12 {
  font-size: 12px;
}
.fs-20 {
  font-size: 20px;
}
</style>
